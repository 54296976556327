<template>
	<b-input-group>
		<b-form-input
			:value="rendAddress(addressPayload)"
			readonly
			:class="{
				'is-invalid': isSubmitted && error,
				'br-none': !disabled
			}"
			:disabled="disabled"
			@keydown.enter.prevent
		/>
		<b-input-group-append v-if="!disabled">
			<b-input-group-text
				class="cursor-pointer bg-color-white input-group-text-bg-white"
				:class="{
					'is-invalid': isSubmitted && error
				}"
			>
				<div :title="FormMSG(12, 'Add')" v-b-tooltip.hover.top v-if="checkAddress === false" @click="openAdresseModal">
					<component :is="getLucideIcon('PlusCircle')" color="#00A09C" :size="18" :stroke-width="2.25" />
				</div>
				<div class="d-flex align-items-center" v-else>
					<div class="mr-2" :title="FormMSG(13, 'Update')" v-b-tooltip.hover.top @click="openAdresseModal">
						<component :is="getLucideIcon('Edit')" color="#225CBD" :size="18" :stroke-width="2.25" />
					</div>
					<div :title="FormMSG(14, 'Delete')" v-b-tooltip.hover.top @click="handleDeleteAddress">
						<component :is="getLucideIcon('X')" color="#EA4E2C" :size="20" :stroke-width="2.75" />
					</div>
				</div>
			</b-input-group-text>
		</b-input-group-append>
		<b-modal
			header-class="header-class-modal-doc-package"
			ref="modal"
			v-model="isAddressModalOpen"
			:title="rendAddressTitle"
			size="lg"
			@hidden="handleAdressModalCancel"
			modal-class="mui-animation"
			:fade="false"
		>
			<div class="container-layout">
				<AddressForm
					:edit-data="addressPayload"
					:is-submitted="isHitted"
					@address-maplink-change="handleAddressMapLinkChange"
					@change="handleAdressChange"
					@invalid:form="onInvalidForm"
				/>
			</div>
			<template #modal-footer>
				<div class="w-100 d-flex justify-content-end align-items-center">
					<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" @click="handleAdressModalCancel" block>
						{{ FormMSG(3, 'Cancel') }}
					</b-button>
					<b-button size="md" variant="primary" class="w-138-px" @click="handleAddressSubmit" block>
						<div class="d-flex justify-content-center align-items-center">
							<div style="margin-top: 1px">{{ FormMSG(2, 'Save') }}</div>
						</div>
					</b-button>
				</div>
			</template>
		</b-modal>
	</b-input-group>
</template>

<script>
import addressHelperMixin from './addressHelper.mixin';
import languageMessages from '@/mixins/languageMessages';
import * as icons from 'lucide-vue';

export default {
	name: 'MainAddressLocationComponent',
	mixins: [addressHelperMixin, languageMessages],
	props: {
		params: {
			type: Object,
			required: true,
			default: () => {}
		},
		error: {
			type: Boolean,
			require: false
		},
		isSubmitted: {
			type: Boolean,
			require: false
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			isSameAddress: false,
			invalid: false
		};
	},
	computed: {
		rendAddressTitle() {
			return this.params.title;
		}
	},
	mounted() {
		this.isSameAddress = this.addressId === 0;
	},
	methods: {
		/**
		 * @param {String} url
		 */
		handleAddressMapLinkChange(url) {
			this.$emit('address-maplink-change', url);
		},
		getLucideIcon(name) {
			return icons[name];
		},
		onInvalidForm(payload) {
			this.invalid = payload;
		}
	}
};
</script>

<style lang="scss" scoped>
.location_main_address_component_wrapper {
	position: relative;

	.title_address {
		float: left;
		width: calc(100% - 130px);
		span {
			line-height: 27px;
			width: 100%;
			display: inline-block;
		}
	}
	.action_container {
		padding-top: 0px;
		float: right;
		width: 130px;
	}

	@media (max-width: 992px) {
		.title_address {
			float: none;
			width: 100%;
		}
		.action_container {
			float: none;
			width: 100%;
		}
	}
}
</style>
